/* eslint-disable import/prefer-default-export */
export const configTable = [
  {
    key: 'created_at',
    label: 'Tanggal Upload',
    class: 'text-black align-middle text-center p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  {
    key: 'expedition',
    label: 'Ekspedisi',
    class: 'text-black text-center align-middle text-center p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
  },
  {
    key: 'date_retur',
    label: 'Tanggal Invoice',
    class: 'text-black align-middle text-center p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
  {
    key: 'status',
    label: 'status',
    class: 'text-black align-middle text-center p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
  {
    key: 'aksi',
    label: 'aksi',
    class: 'text-black align-middle text-center p-1',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: 600,
    },
    tdClass: 'text-center',
  },
]

export const filterOptions = [
  {
    value: null,
    text: 'Pilih Status',
    disabled: true,
  },
  {
    value: '',
    text: 'Semua',
  },
  {
    value: 0,
    text: 'Perlu Diproses',
  },
  {
    value: 1,
    text: 'Sedang Diproses',
  },
]
